/* gloden ratio 34 21 13 8 5 3 1*/
/* min*3 max*12*/

.councils .context {
    position: relative;
}

.councils .spotlight {
    position: fixed;
    background-color: #ffffff99;
    -webkit-box-shadow: 2px 2px 2px #ffffff99;
    -moz-box-shadow:    2px 2px 2px #ffffff99;
    box-shadow:         2px 2px 2px #ffffff99;
}

.councils .namecard {
    position: relative;
    padding: 4px;

    -webkit-box-shadow: 2px 2px 2px #44444444;
    -moz-box-shadow:    2px 2px 2px #44444444;
    box-shadow:         2px 2px 2px #44444444;
    
    min-width: 168px !important;
    width: 82.5vw !important;    
    max-width: 672px !important;
}

.councils .namecard.bgpink {
    background-image: linear-gradient(120deg, #ffbf96cc, #fe9496cc, #fe7096cc);
}

.councils .namecard.bgblue{
    background-image: linear-gradient(120deg, #8fcaf9cc, #4bcbebcc, #047edfcc);

}

.councils .namecard.bggreen{
    background-image: linear-gradient(120deg, #83d9d2cc, #1bcfb4cc, #0ccedfcc);
}


/* 21*3 > 21*10  */
.councils .namecard .namecard-img {
    min-width: 63px !important;
    min-height: 63px !important;
    width: 31.5vw !important;
    height: 31.5vw !important;
    max-width: 252px !important;
    max-height: 252px !important;
    /* background-color: black; */
}

.councils .namecard img {
    object-fit: cover;
    /* background-image: '/images/councils/bg-logo.png'; */
    width: 100% !important;
    height: 100% !important;
    background-color: rgba(255,255,255,0.3);
    /* background-blend-mode:saturation; */
    background-size: cover;
    /* filter: opacity(0.6) */
    /* -webkit-filter: sepia(100%) hue-rotate(90deg) saturate(400%); */
    /* filter: sepia(0%) hue-rotate(90deg) saturate(400%); */
}

.councils .namecard .description {
    text-align: start;
}

.councils .namecard .title {
    overflow: hidden;
    min-height: 15px !important;
    height: 7.5vw !important;
    max-height: 60px !important; 
    font-size: max(9px, min(4.5vw, 36px)) !important;
    /* background-color: #fff6fb; */
}

.councils .namecard .detail {
    font-size: max(4.5px, min(2.25vw, 18px)) !important;
}