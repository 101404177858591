footer{
    /* font-size: calc(12px + 0.5vmin); */
    color: #999999;
    text-shadow: #335577;
    padding: auto;
    margin: auto;
    justify-content:space-around;
    width: 100%;
    /* background: #f8f9fa; */
}



.footer-content{
    display: flex;
    justify-content:space-around;
    width: 100%;
    margin: 0px;
    padding: 0px;
    margin-top: 20px;
}
.footer-content-1{
    text-align: start;
    min-width: 40%;
    max-width: 90%;
}

.footer-content-2{
    align-items: flex-end;
    align-content:flex-end;
    text-align: end;
    min-width: 40%;
    max-width: 90%;
}

.footer-social-icon{
    /* max-width: 40px; */
    max-height: 40px;
}

@media only screen and (max-width: 768px) {
    .footer-content-2{
        align-items: center;
        text-align: center;
    }
}

#footer {
    background: linear-gradient(to bottom, #fff6fb , white 15%);
}



.copyright{
    background: #fbfbfb;
    margin: auto;
    padding: auto;
    font-size: calc(10px + 0.1vmin);
    letter-spacing: 1px;
    text-align: center;
    justify-items: center;
    vertical-align: bottom;
}

#footer-test{
    background-color: rgba(255, 0, 0, 0.1);
    /* max-width: 768px; */
}