.navbar
{
    /* background-color: darkorange; */
    font-family: 'Prompt', sans-serif !important;
    font-size: calc(16px + 0.5vmin);
    padding: auto;
    text-align: start;
    vertical-align: top;
    position: -webkit-sticky !important;;
    position: -moz-sticky !important;;
    position: -o-sticky !important;;
    position: -ms-sticky !important;;
    position: sticky !important;;
    /* width: 500px; */
    margin-right: 0px;
    /* padding: 0px; */
    /* padding-top: 5px !important; */
    /* background-color: darkorange; */
}

.navbar.navaway{
    /* background-color: darkorange; */
    margin-bottom: 128px;
    -webkit-box-shadow: 5px 5px 5px #444;
    -moz-box-shadow:    5px 5px 5px #444;
    box-shadow:         0px 5px 5px #444;
    z-index:999;
}

.alwaycollapse {
    margin-bottom: auto !important;
}

/* .navbar-container-lg{
    background-color: yellow;
    margin-left: 100px;
} */

.navbar-row{
    /* height: 100%; */
    align-items: flex-start;
}

.navbar-col1{
    position: relative;
    /* transition: all 0.6s ease-in-out; */
    min-width: 128px;
    width: 160px;
    text-align: center;
}
/* .navbar-Brand{
    width: 140px;
    background-color: aqua;
}
.navbar-col2{
    position: relative;
    transition: all 0.6s ease-in-out;
} */

/* .navbar-context-lg{
    background-color: green;
    width: 100%;
} */

.navbar-contact{
    /* background-color: aqua; */
    text-align: right;
    transition: all 0.6s ease-in-out;
}

.navbar-contact.navaway{
    /* background-color: aqua; */
    opacity: 0;
    visibility: collapse;
    max-height: 0px;
}

.img-logo{
    /* background-color: red; */
    transition: all 0.32s ease-in-out;
    max-height: 152px;
} 

.img-logo:hover{
    /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: ani_shake 1s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

.img-logo.navaway{
    max-height: 48px;
}

.img-logo-md{
    /* background-color: red; */
    transition: all 0.32s ease-in-out;
    max-height: 48px;
} 

.navbar-text1-md{
    /* max-height: 8vw; */
    font-size: 16px;
    background-image: linear-gradient(90deg, #7777bb, #555555);
  
    /* Set the background size and repeat properties. */
    background-size: 100%;
    background-repeat: repeat;

    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
}

.navbar-text2-md{
    font-size: 16px;
    color: #555555;
}
/* .navbar-nav{
    background-color: red;
} */

/* .navbar-container-md{
    background-color: blue;
} */

.navbar-collapse{
    transition: all 0.23s linear;
    border-top: solid 1px #9d9d9d;
}
.navbar-collapse.navaway{
    border-top: none;    
}

.navbar-lang{
    border: 0px;
    background: none;
    height: 42px;
    width: 42px;
    margin: 0px;
    padding: 0px;
}

.navbar-toggler {
    border: none;
    /* background-color: red; */

    &:hover, &::after, &::selection, &:focus, &:active, &:focus-visible{
        border: none;
    }
}

.header-icon{
    height: 32px;
    object-fit: cover;
}


/* .navbar-lang:hover{
    transform: scale(0.95);
} */

