.breed_profile .winter {
    width: 30%;
    max-width: 192px;
    object-fit: contain;
}

.breed_profile .context {
    text-align: start;
}

.breed_profile .context .para3 {
    animation: 1.2s ease-out 0s 1 ani_fadeInTop;
}

.breed_profile .context .para4 {
    animation: 1.8s ease-out 0s 1 ani_fadeInTop;
}

.breed_profile .context .para5 {
    animation: 2.4s ease-out 0s 1 ani_fadeInTop;
}