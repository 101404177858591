
.contact_us .content {
    position: relative;
    text-align: start;
    /* align-items: start; */
    /* align-content: start; */
    /* background-color: rgba(255, 0, 0, 0.2); */
}

.contact_us .content.namecard{
    max-width: 510px;
}

.contact_us .content a{
    text-decoration: none;
}

.contact_us h3 {
    text-decoration: underline;
    text-shadow: .5px .5px #44444444;
    
    /* background-image: linear-gradient(90deg, #ffbf96, #fe9496, #fe7096); */
    background-image: linear-gradient(120deg, #ff84c6, #8fcaf9 75%, #7bc9ff);
    /* background-image: linear-gradient(90deg, #8fcaf9, #4bcbeb, #047edf); */

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    
}

h3 .t1 {
    /* text-decoration: underline;
    text-shadow: 1px 1px #44444411;
    background-image: linear-gradient(90deg, #ffbf96, #fe9496, #fe7096);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent; */
}

h3 .t2 {
    /* text-decoration: underline;
    text-shadow: 1px 1px #44444411;
    background-image: linear-gradient(90deg, #047edf, #4bcbeb, #8fcaf9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent; */
}

.contact_us img.img-icon {
    max-width: 32px;
    max-height: 32px !important;
    margin-right: 4px;
    /* background-color: red; */
}

.contact_us .deco {
    position:absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    /* background-color: red; */
}

.contact_us .deco .faye {
    position: absolute;
    width: 35%;
    right: 0px;
    bottom: 2vmin;
}

.contact_us .map {
    max-width: 99% !important;
    min-width: 300px;
    min-height: 300px;
}
