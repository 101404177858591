
.subheader{
    display: flex;
    justify-content: right;
    /* background: #CDDDEA; */
    /* background:  linear-gradient(to bottom right, #CDDDEA, #47DFEC); */
    
    margin-bottom: 40px;   /* for winter foot */
    text-align: right;
    min-height: 168px;
}
.subheader.small{
    margin-bottom: 20px;
    min-height: 64px;
}

.subheader.bg-blue {
    background:  linear-gradient(to bottom right, #047edfcc, #4bcbebcc 70%, #8fcaf9cc);
}

.subheader.bg-green {
    background:  linear-gradient(to bottom right, #0ccedf, #1bcfb4 70%, #83d9d2);
}

.subheader.bg-orange-pink {
    background:  linear-gradient(to bottom right, #fe7096, #fe9496 70%, #ffbf96);
}

.subheader-left{
    /* display: flex; */
    /* padding: 10px; */
    text-align: left;
    /* align-items: start; */
    /* justify-items: left; */
    /* justify-content: left; */
    /* width: 90%; */
    /* min-height: 176px; */
    z-index: 10;
    /* background-color: red; */
}
.subheader.small .subheader-left{
    min-height: 88px;
    
    
}
.subheader-right{
    position: relative;
    right: 0px;
    /* display: flex; */
    /* justify-content: center; */
    /* background-color: red; */
}

.subheader figure{
    /* display:box; */
    /* position: relative; */
    /* height: 174px; */
    /* padding: 0px; */
    /* right: 10px; */
}

/* hr{
    margin: 0px;
} */

.subheader #winter{
    position: absolute;
    /* offset-position; */
    /* right: 30px; */
    /* top: 0px; */
    /* margin-top: 0.5vmin; */
    z-index: -0;
    right: 0px;
    bottom: 0px;
    /* width: 25.6vmin; */
    /* width: auto; */
    max-width: auto;
    height: 176px;
    transition: all 0.3s ease-in-out;
    animation: 6s ani_winter;
    animation-iteration-count: infinite;
}

.subheader.small #winter{
    height: 88px;
}

.subheader #winter-foot{
    overflow: hidden;
    position: absolute;
    /* offset-position; */
    /* margin-right: 130px; */
    

    right: -5px;
    bottom: -48px;
    /* margin-top: 170px; */
    width: auto;
    height: 78px;

    /* top: 30px; */
    /* margin-top: 14.8vmin; */
    transition: all 0.3s ease-in-out;
    transform: translate(-4px, 0px) rotate(2.5deg);
    animation: 6s 3s ani_foot;
    animation-iteration-count: infinite;
}

.subheader.small #winter-foot{
    overflow: hidden;
    position: absolute;
    right: -2.5px;
    height: 38px;
    bottom: -24px;
}