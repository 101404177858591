.App {
  text-align: center;
  color: #606060;
  background-color: #fff6fb;
  font-family: 'Prompt', sans-serif !important;
  white-space: pre-line;
}

/* color Theme */
/* orange - pink  > ffbf96, fe9496, fe7096 */
/* blue           > 8fcaf9, 4bcbeb, 047edf */
/* green          > 83d9d2, 1bcfb4, 0ccedf */


.whitepink{
  color: #fff6fb;
}

.blue-em {
  color: #4bcbebcc;
  text-shadow: .5px .5px #44444444;
}

.pink {
  color: #fe9496cc;
}

.green {
  color: #1bcfb4cc;
}

.deco-circle1 {
  display: flex;
  width: 30vh;
  height: 30vh;
  /* background-color: green; */
  border-radius: 50%;
}

.deco-circle2 {
  display: flex;
  width: 45vh;
  height: 45vh;
  /* background-color: green; */
  border-radius: 50%;
}

.bcolor-fade-white {
  background-color: #ffffff22;
}

.inner-shadow{
  text-shadow: -1px -1px #44444444;
}

.whitebox-shadow {
  background-color: white;
  -webkit-box-shadow: 2px 2px 2px #44444444;
  -moz-box-shadow:    2px 2px 2px #44444444;
  box-shadow:         2px 2px 2px #44444444;
}

.shadow {
  -webkit-box-shadow: 2px 2px 2px #44444444;
  -moz-box-shadow:    2px 2px 2px #44444444;
  box-shadow:         2px 2px 2px #44444444;
}

.maxw-576, .maxw-sm{
  max-width: 576px !important;
}

.maxw-768, .maxw-md{
  max-width: 768px !important;
}

.maxw-992, .maxw-lg{
  max-width: 992px !important;
}

.maxw-1200, .maxw-xl{
  max-width: 1200px !important;
}

.maxw-1400, .maxw-xxl{
  max-width: 1400px !important;
}

.maxw-1920, .maxw-xxxl{
  max-width: 1920px !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

h2{
  font-size: calc(18px + 0.5vmin);
}

h3{
  font-size: calc(16px + 0.5vmin);
  
}

h4{
  font-size: calc(14px + 0.5vmin);
  padding: auto;
  margin: auto;
}



a:link, link, .link {
color:#555555 !important; 
-webkit-transition: color 0.5s ease-in-out;
-moz-transition: color 0.5s ease-in-out;
-ms-transition: color 0.5s ease-in-out;
-o-transition: color 0.5s ease-in-out;
transition: color 0.5s ease-in-out;
}
a:visited{
color:#555555 !important; 
}
a:hover, link:hover, .link:hover {
color: #3294D9 !important;
}
a:active, link:active, .link:active{
color:#5BC7CE !important;
opacity: 80%;
}


.link-icon{
transform: scale(1);
/* height: 60px; */
}
.link-icon:hover{
transform: scale(0.95);
}

.ani_fadeIn{
animation: 0.6s ease-out 0s 1 ani_fadeIn;
flex: auto;
}

.transi-ease-int-out{
  transition: all 0.32s ease-in-out;
}

.ani-fadeInTop{
animation: 0.6s ease-out 0s 1 ani_fadeInTop;
}

.ani-fadeInLeft{
animation: 0.6s ease-out 0s 1 ani_fadeInLeft;
}

.ani-fadeInRight{
animation: 0.6s ease-out 0s 1 ani_fadeInRight;
}

.ani_circling {
  animation: 1s 1s ani_circling;
  animation-iteration-count: infinite;
}

@keyframes App-logo-spin {
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
}

@keyframes ani_shake {
0% { transform: translate(1px, 1px) rotate(0deg); }
10% { transform: translate(-1px, -1px) rotate(-1deg); }
20% { transform: translate(-1px, 0px) rotate(1deg); }
30% { transform: translate(1px, 1px) rotate(0deg); }
40% { transform: translate(1px, -1px) rotate(1deg); }
50% { transform: translate(-1px, 1px) rotate(-1deg); }
60% { transform: translate(-1px, 1px) rotate(0deg); }
70% { transform: translate(1px, 1px) rotate(-1deg); }
80% { transform: translate(-1px, -1px) rotate(1deg); }
90% { transform: translate(1px, 1px) rotate(0deg); }
100% { transform: translate(1px, -1px) rotate(-1deg); }
}

@keyframes ani_fadeIn {
0% {
  transform: translateX(0%); opacity: 0;
}
100% {
  transform: translateX(0); opacity: 1;
}
}

@keyframes ani_fadeInLeft {
0% {
  transform: translateX(-10%); opacity: 0;
}
100% {
  transform: translateX(0); opacity: 1;
}
}

@keyframes ani_fadeInRight {
0% {
  transform: translateX(+10%); opacity: 0;
}
100% {
  transform: translateX(0); opacity: 1;
}
}

@keyframes ani_fadeInTop {
0% {
  transform: translateY(-10%); opacity: 0;
}
100% {
  transform: translateY(0); opacity: 1;
}
}

@keyframes ani_fadeInBottom {
0% {
  transform: translateY(+10%); opacity: 0;
}
100% {
  transform: translateY(0); opacity: 1;
}
}