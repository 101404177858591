.NCheader-row {
    /* background-color: #c4c4c4; */
    background-image: linear-gradient(to bottom, #0f131e, #1e263c);
    color: #fff;
    text-align: center;
    font-weight: bold;
    align-items: center;
}

.nowarp {
    white-space: nowrap;
}
.text-align-start {
    align-items: start;
    text-align: start;
    align-content: start;
}

.text-align-end {
    text-align: end;
}
.NCdecimalinput-tiny {
    max-width: 40px;
    text-align: end;
    padding-right: 4px;
    height: 24px;
    margin-top: 4px !important;
}

.NCdecimalinput {
    max-width: 80px;
    text-align: end;
    height: 24px;
    margin-top: 4px !important;
}

.text-small {
    font-size: 0.75rem;
}

.text-golden {
    background: linear-gradient(135deg, #ffcc00, #ffcc00 49%, #ffae42 50%, #ffae42); /* Gold gradient */
    -webkit-background-clip: text; /* Clip background to text */
    -webkit-text-fill-color: transparent; /* Make text transparent */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Shadow effect */
}


.NGtokencal .NGtable {
    border-radius: 5px;
    background-color: #ffffff55;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.NGHeader {
    position: relative;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #0f131e;
    overflow: hidden;
    max-height: 168px;
}

.NGHeadervid {
    transform: none;
    margin-left: 25% !important;
    background-color: #0f131e;
}

.NGHeader-context {
    position: absolute;
    top: 0px;
    left: 0px;
    color: #ffffff;
    text-shadow:rgba(0, 0, 0, 0.36) 0px 1px 1px,
}

.NGHeader-context.line1 {
    /* top: 10% !important; */
    /* left: 0px !important; */
}

