@media (orientation: landscape) {
    .home .welcome {
        position: relative;
        width: 100%;
        height: calc(99vw / 2.5);
        /* background-color: black; */
        margin-bottom: 1vw;
        padding: 0px;
    }
}

@media (orientation: portrait) {
    .home .welcome {
        position: relative;
        width: 100%;
        height: calc(99vw / 2.5 + (100vh - 100vw) * 0.8 );
        /* background-color: black; */
        margin-bottom: 2vh;
        padding: 0px;
    }
}

.home .welcome .img-clover {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    /* background-color: red; */
    top: 0;
}

.home .welcome .bg {
    position: relative;
    width: 100%;
    height: 85%;
    /* background-color: #cdddea; */
    background-image: linear-gradient(to bottom, #4bcbeb88, #4bcbeb44, #4bcbeb22);
}

.home .welcome .dog-group1 {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: calc(50% - 30vw + 30vh);
    max-width: 50%;
    height: 80%;
    max-height: 80%;
    /* background-color: #ff000022; */
}

.home .welcome .dog1 {
    position: absolute;
    left: 0px;
    bottom: 0px !important;
    max-width: 100% !important;
    max-height: 100% !important;
}

.home .welcome .heart1 {
    position: absolute;
    object-fit: contain;
    z-index: 10;
    width: 25%;
    height: 15%;
    top: max(-7%, calc(30vh - 40vw));
    left: 68%;

    animation: 5s ease-in-out 1s ani_heartbeat1;
    animation-iteration-count: infinite;
}

.home .welcome .heart2 {
    position: absolute;
    object-fit: contain;
    z-index: 10;
    width: 15%;
    height: 7.5%;
    top: max(-15%, calc(30vh - 45vw));
    left: 85%;
    transform: translate(0px, 0px) rotate(15deg);
}

.home .welcome .dog-group2 {
    position: absolute;
    bottom: 0;
    right: 40%;
    width: calc(60% - 30vw + 30vh);
    max-width: 65%;
    height: 65%;
    max-height: 65%;
    /* background-color: #ff000022; */
}

.home .welcome .dog2 {
    position: absolute;
    left: 0px;
    bottom: 0px !important;
    max-width: 100% !important;
    max-height: 100% !important;
    /* background-color: blue; */
}

.home .welcome .heart3 {
    position: absolute;
    object-fit: contain;
    /* z-index: 10; */
    width: 15%;
    height: 7.5%;
    top: max(-5%, calc(30vh - 45vw));
    left: 10%;
    transform: translate(0px, 0px) rotate(-10deg);
    
    animation: 3s ease-in-out 1s ani_heartbeat2;
    animation-iteration-count: infinite;
}

.home .welcome .heart4 {
    position: absolute;
    object-fit: contain;
    /* z-index: 10; */
    width: 18%;
    height: 12%;
    top: max(-20%, calc(30vh - 55vw));
    left: 5%;
    transform: translate(0px, 0px) rotate(-35deg);
    opacity: 0.4;
    
    /* animation: 3s ease-in-out 1s ani_heartbeat2; */
    /* animation-iteration-count: infinite; */
}

.home .welcome .dog3 {
    position: absolute;
    object-fit: contain;
    max-height: 80%;
    max-width: 43%;
    bottom: 10%;
    left: calc(20% + 7vw - 7vh);
}



.home .welcome .eclipse-container {
    position: absolute;
    width: 100%;
    height: 100%;
}

.home .welcome .eclipse {
    position: relative;
    object-fit: contain;
    width: 30%;
    /* max-height: 80%; */
    min-height: 200px;
    max-height: 422px;
    min-width: 200px;
    max-width: 422px;
    /* left: 35%; */
    bottom: 80%;
    /* bottom: -2.5%; */
    /* left: calc(8% + 15vw - 15vh); */

}


.home .lboxs_container {
    /* background-color: red; */
}

.home .lbox-item {
    /* background-color: blue; */
    position: relative;
    min-width: 256px;
    max-width: 448px;
}

.home .lbox-img {
    object-fit: contain;
}

.home .lbox-context {
    position: absolute;
    width: 75%;
    height: 75%;
    text-align: start;
    color: #606060;
    z-index: 1;
    /* background-color: rgba(255,255,255,0.3); */
}

.home .lbox-title {
    font-size: min(max(12px, 3vw),36px);
}

.home .lbox-detail {
    font-size: min(max(6px, 1.5vw),18px);
}


.home .lbox-context.item1 {
    margin-top: 5.9%;
    height: 69.1% !important;
}

.home .lbox-context.item3 {
    width: 58%;
}

.home .lbox-item .rmbox {
    position: absolute;
    background-color: white;
    left: 7%;
    bottom: 14%;
    font-size: min(max(12px, 1vw),14px);
    color: #606060;
    z-index: 2;
}

@media screen and (max-width: 576px) {
    .home .lbox-item {
        /* background-color: blue; */
        position: relative;
        min-width: 80vw;
        max-width: 100vw;
    }

    .home .lbox-title {
        font-size: min(max(12px, 6vw), 36px);
    }
    
    .home .lbox-detail {
        font-size: min(max(6px, 3vw), 18px);
    }
}

div.body-end{
    /* height: 500px; */
    position: relative;
    transition: all 0.3s ease-in-out;
}

div.body-end .deco{
    position: absolute;
    overflow: hidden !important;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    
}

div.body-end .deco-circle1 {
    position: absolute;
    top: -1vh;
    left: -4vh;
    transition: all 0.3s ease-in-out;
    animation: 7.3s ani_shake;
    animation-iteration-count: infinite;
}

div.body-end .deco-circle2 {
    position: absolute;
    top: 11vh;
    left: 15vh;
    transition: all 0.3s ease-in-out;
    animation: 9s ani_winter;
    animation-iteration-count: infinite;
}

div.body-end-context{
    height: 360px;
    /* background-color: #f6edc6; */
    /* background-image: linear-gradient(to bottom right, #efdfbf, #f8fed7, #f6edc6); */
    background-image: linear-gradient(to bottom right, #ffbf96cc, #fe9496cc, #fe7096cc);
    -webkit-box-shadow: 2px 2px 2px #44444444;
    -moz-box-shadow:    2px 2px 2px #44444444;
    box-shadow:         2px 2px 2px #44444444;

    /* overflow: hidden; */
    text-align: center;
    align-items: center;
    justify-content: center;
    vertical-align: center;
    /* display: flex; */
    transition: all 0.3s ease-in-out;
    position: relative;
}

#i-bg-winter{
    position: absolute;
    /* offset-position; */
    right: calc((max(100%, 1920px) - 1920px)/2 + 30px);
    bottom: 0px;
    z-index: 0 !important;
    width: 350px;
    height: 350px;
    transition: all 0.3s ease-in-out;
    animation: 6s ani_winter;
    animation-iteration-count: infinite;
}
#i-bg-foot{
    overflow: hidden;
    position: absolute;
    /* offset-position; */
    right: calc((max(100%, 1920px) - 1920px)/2 + 0px);
    bottom: -120px;
    transition: all 0.3s ease-in-out;
    transform: translate(-4px, 0px) rotate(2.5deg);
    animation: 6s 3s ani_foot;
    animation-iteration-count: infinite;
    width: 153px;
    height: 200px
}
@media only screen and (max-width: 1080px) {
    div.body-end-context{
        height: 630px;
    }
}
@media only screen and (max-width: 768px) {
    div.body-end-context{
        height: 780px;
    }
}

p.body-end-txt{
    position: relative;
    font-size: calc(36px + 1vmin);
    margin: 0;
}
l.wordblock{
    overflow: hidden;
    word-wrap: normal;
    word-break: keep-all;
    display: inline-flex;
}

button.bt-contact{
    /* background-color: #3294d9; */
    border-radius: 45px;
    /* height: 48px; */
    /* width: calc(256px); */
    padding: 12px;
    margin: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 21px;
    padding-right: 21px ;
    border-style: none;
    font-size: calc(28px + 1vmin);
    color: white;
}
button.bt-face{
    background-color: #1877f2;
    
}
button.bt-line{
    background-color: #01ba03;
}

button.bt-contact > img{
    max-height: 80%;
    width: auto;
    margin-right: 20px;
    height: calc(48px + 1vmin);
    z-index: 100 !important;
}

.gallery-list-item {
    cursor: pointer;
    object-fit: cover !important;
    width: 100% !important;
    height: 100% !important;
}

@keyframes ani_winter {
    0% { transform: translate(0px, 0px); }
    50% { transform: translate(3px, 1px); }
    100% { transform: translate(0px, 0px); }
}

@keyframes ani_foot {
    0% { transform: translate(-4px, 0px) rotate(2.5deg); }
    15% { transform: translate(0px, 0px) rotate(0deg); }
    25% { transform: translate(-4px, 0px) rotate(2.5deg); }
    65% { transform: translate(-4px, 0px) rotate(2.5deg); }
    77% { transform: translate(-2px, 0px) rotate(1.2deg); }
    89% { transform: translate(-4px, 0px) rotate(2.5deg); }
    100% { transform: translate(-4px, 0px) rotate(2.5deg); }
}

@keyframes ani_heartbeat1{
    0% {
        transform: scale(1); opacity: 1;
    }
    10% {
        transform: scale(1); opacity: 0;
    }
    20% {
        transform: scale(0.50); opacity: 0;
    }
    30% {
        transform: scale(1); opacity: 1;
    }
    100% {
        transform: scale(1); opacity: 1;
    }
}

@keyframes ani_heartbeat2{
    0% {
        transform: scale(1); opacity: 1; transform: rotate(-10deg);
    }
    10% {
        transform: scale(0.30); opacity: 0; transform: rotate(-10deg);
    }
    30% {
    transform: scale(1); opacity: 1; transform: rotate(-10deg);
    }
    100% {
        transform: scale(1); opacity: 1; transform: rotate(-10deg);
    }
}